
.custom-cursor {
    cursor: none;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.cursor-element {
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, -50%);
    cursor: none;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #4521A0 !important;
    border-radius: 6px !important;
}

::-webkit-scrollbar-track {
    background: #fff;
}